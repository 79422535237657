import React, { useRef, useState } from 'react'
import logo from '..//assests/logo.png';
import ios from '..//assests/ios.png'
import vs from '../assests/vs.png'
import live from '../assests/live.png'
import viewer from '../assests/viewer.png'
import android from '..//assests/android.png'
import biglogo from '../assests/biglogo.png'
import mobileimage from '../assests/mobileimage.png'
import avatargirl from '../assests/avatargirl.png'
import footer from '../assests/footerlogo.png'
import { MenuOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Image, Input, Row } from 'antd';

import video from '../assests/video.mp4'
import playButton from "../assests/play_ button.png"
import pauseBtn from "../assests/pause.png"
import './rico.css'
import { Link } from 'react-scroll';
const Rico = () => {
    const [form] = Form.useForm();

    const slider = useRef(null);
    const [videoPlay, setVideoPlay] = useState(false);
    const [open, setOpen] = useState(false);
    const videoRef = useRef(null);

    console.log("videoPlay", videoPlay);

    /* Submitting Contact Form */
    const onSubmitForm = (values) => {
        console.log('Success:', values);
        form.resetFields();
    };

    /* Handling Video */
    const playVideo = () => {
        setVideoPlay(!videoPlay);
        if (!videoPlay) {
            videoRef.current.play();
        } else {
            videoRef.current.pause();

        }
    };
    return (

        <>
            {/* HEADER  */}
            <div className='head_container'>
                <Row className='common_container header__div'>
                    <Col className='heder_space' md={24} lg={4} xs={24} sm={24}>
                        <img className='logo' src={logo} alt='logo' />
                        <div className='showMenu_btn' onClick={() => setOpen(true)} >
                            <MenuOutlined style={{ fontSize: '1.5rem' }} />
                        </div>
                    </Col>
                    <Col md={24} lg={16} xs={24} sm={24} className={'heading_detail'} style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Link className="heading" activeStyle={{ color: '#FFFFFF' }} to="Home" spy={true} smooth={true} delay={50} duration={50} offset={-70}>Home</Link>
                        <Link className="heading" activeStyle={{ color: '#FFFFFF' }} to="Features" spy={true} smooth={true} delay={50} duration={50} offset={-70}>Features </Link>
                        <Link className="heading" activeStyle={{ color: '#FFFFFF' }} to="About" spy={true} smooth={true} delay={50} duration={50} offset={-80}>About us</Link>
                        <Link className="heading" activeStyle={{ color: '#FFFFFF' }} to="Download" spy={true} smooth={true} delay={50} duration={50} offset={-70}>
                            Download
                        </Link>
                        <Link className="heading" activeStyle={{ color: '#FFFFFF' }} to="Contact" spy={true} smooth={true} delay={50} duration={50} offset={-70}>
                            Contact Us!
                        </Link>
                    </Col>
                    <Col md={24} lg={4} xs={24} sm={24} >
                        <div className='download_button' >
                            <img src={ios} alt='logo' />
                            <img src={android} alt='logo' />
                        </div>
                    </Col>
                </Row>
            </div>
            {/* HOME SECTION   */}
            <section id="Home" className='background_img'>
                <div className='swap_text '>
                    <span className='editor'> <span style={{ fontWeight: '500' }}>#1</span> Editiors Choice App of 2023</span>
                    <br />
                    <div className='mx-auto center_text'>
                        <span className='home_heading'><span style={{ fontWeight: '800' }}> RICO</span> a safe digital space for Short video sharing.</span>
                        <p className='paragraph'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                    </div>
                    <Link to="Download" spy={true} smooth={true} delay={50} duration={50} offset={-70}>
                        <Button className='try_button' type="primary">Try for free</Button>
                    </Link>
                </div>

            </section>
            {/* Awesome features */}
            <section className=' awesome_feature' id="Features">
                <div className='features_section'>
                    <p className='title'>Awesome features</p>
                    <p className='paragraph'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. </p>
                </div>
                <Row gutter={[]} style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <Col sm={24} md={24} lg={8} xl={8}>
                        <div className='awesome_MainDiv'>
                            <img className='features_image' src={vs} alt="image" />
                            <div style={{ textAlign: 'center', width: '100%' }}>
                                <h6 style={{ marginTop: 12, fontSize: '1.2rem', color: '#FFCA00', fontWeight: 600 }}>Player Knockout (PK)</h6>
                                <p className='paragraph'>Lorem ipsum dolor sit amet consetetur sadipscing elitr, sed diam</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={24} md={24} lg={8} xl={8}>
                        <div className='awesome_MainDiv'>
                            <img className='features_image' src={live} alt="image" />
                            <div style={{ textAlign: 'center', width: '100%' }}>
                                <h6 style={{ marginTop: 12, fontSize: '1.2rem', color: '#FFCA00', fontWeight: 600 }}>Live Stream</h6>
                                <p className='paragraph'>Lorem ipsum dolor sit amet consetetur sadipscing elitr, sed diam </p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={24} md={24} lg={8} xl={8}>
                        <div className='awesome_MainDiv'>
                            <img className='features_image' src={viewer} alt="image" />
                            <div style={{ textAlign: 'center', width: '100%' }}>
                                <h6 style={{ marginTop: 12, fontSize: '1.2rem', fontWeight: 600, color: '#FFCA00' }}>Short Videos</h6>
                                <p className='paragraph'>Lorem ipsum dolor sit amet consetetur sadipscing elitr, sed diam </p>
                            </div>
                        </div>
                    </Col>
                </Row>

                <div style={{ textAlign: 'center' }}>
                    <Link to="Contact" spy={true} smooth={true} delay={50} duration={50} offset={-70}>
                        <Button className='contact_button' type="primary" >Contact Us</Button>
                    </Link>
                </div>
            </section >
            {/* VIDEO   */}
            <section className='common_container' style={{ backgroundColor: '#000' }}>
                <div style={{ position: 'relative' }}>
                    <video ref={videoRef} width="100%" controls={false} autoPlay={videoPlay} muted>
                        <source src={video} type="video/mp4" />
                    </video>
                    <div role='button' className='play_button pause_button' onClick={() => playVideo()}>
                        <img className='play_logo' src={videoPlay ? pauseBtn : playButton} alt="Image" />
                    </div>
                </div>
            </section>
            {/* WHY RICO */}
            <section id="About" className='aboutus_background'>
                <Row gutter={[]}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <img className='' src={biglogo} alt='logo' style={{ width: '60%', marginTop: '16px' }} />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <div className='leftside_about '>
                            <h1 className='title '>Why Rico.video</h1>
                            <article className='paragraph'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</article>
                            <p className='paragraph'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy. Ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy. Ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy.</p>
                            <Link to="Download" spy={true} smooth={true} delay={50} duration={50} offset={-70}>
                                <Button className='try_button' type="primary" >Download</Button>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </section >
            {/* DOWNLOAD SECTION  */}
            <section id='Download'>
                <div className='awesome_feature'>
                    <p className='title'>Download the App Now</p>
                    <p className='paragraph'>Stop putting the recruiting process in the hands of others. Connect with athletes and colleges from across the nation to enhance your recruiting experience.</p>
                    <div className='playestore_button' >
                        <img className='playstore_button' src={ios} alt='logo' />
                        <img className='playstore_button' src={android} alt='logo' />
                    </div>
                    <div style={{ marginTop: '25px' }}>
                        <img className='mobile_image' src={mobileimage} alt="image" />
                    </div>
                </div>
            </section>
            {/* contact */}
            <section style={{ display: 'grid', placeItems: 'center' }} className='download_container' id="Contact">
                <Row gutter={[]}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} className='contact_form'>
                        <p className='title'>Contact Us!</p>
                        <p className='paragraph'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                        <Form name="form" form={form} onFinish={onSubmitForm} layout="vertical" className="modal_form" >
                            <Form.Item rules={[{ required: true, message: "Please Enter Name" }]} name="name" >
                                <Input className='form_style' placeholder="Name*" />
                            </Form.Item>
                            <Form.Item rules={[{ required: true, message: "Please Enter Email" }]} name="email"  >
                                <Input className='form_style' placeholder="E-mail*" />
                            </Form.Item>
                            <Form.Item rules={[{ required: true, message: "Please Enter Message" }]} name="message"  >
                                <Input className='messageform_style' placeholder="Message*" />
                            </Form.Item>
                            <Form.Item >
                                <Button type='ghost' className="contact_button" htmlType='submit'>Send</Button>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} className='contact_girl' style={{ marginTop: '25px ' }}>
                        <img className='avatargirl' src={avatargirl} alt='logo' />
                    </Col>
                </Row >
            </section>
            {/* FOOTER  */}
            <section >
                <div className='footer_parent'>
                    <div className='footer_logo'>
                        <img src={footer} alt='logo' width="10%" />
                    </div>
                    <div className='footer_detail'>
                        <Link className="heading" activeStyle={{ color: '#FFFFFF' }} to="Home" spy={true} smooth={true} delay={50} duration={50} offset={-70}>Home</Link>
                        <Link className="heading" activeStyle={{ color: '#FFFFFF' }} to="Features" spy={true} smooth={true} delay={50} duration={50} offset={-70}>Features </Link>
                        <Link className="heading" activeStyle={{ color: '#FFFFFF' }} to="About" spy={true} smooth={true} delay={50} duration={50} offset={-80}>About us</Link>
                        <Link className="heading" activeStyle={{ color: '#FFFFFF' }} to="Download" spy={true} smooth={true} delay={50} duration={50} offset={-70}>
                            Download
                        </Link>
                        {/* <Link className="heading" activeStyle={{ color: '#FFFFFF' }} to="/https://admin.rico.video/#/termsCondition" spy={true} smooth={true} delay={50} duration={50} offset={-70}>
                        </Link> */}
                         <a href='https://admin.rico.video/#/termsCondition' target='_blank' style={{color:"#fff",textDecoration:"none"}} > Terms & Conditions</a>  
                         <a href='https://admin.rico.video/#/privacyPolicy' target='_blank' style={{color:"#fff",textDecoration:"none"}} >   Privacy Policy</a>  
                         <a href='https://admin.rico.video/#/aboutUs' target='_blank' style={{color:"#fff",textDecoration:"none"}} >   About Us</a>  
                  
                        <Link className="heading" activeStyle={{ color: '#FFFFFF' }} to="Contact" spy={true} smooth={true} delay={50} duration={50} offset={-70}>
                            Contact Us!
                        </Link>
                    </div>
                </div>
                <div className='bottom'>
                    <article > &copy;  2023 - All Rights Reserved - RICO.Video | Designed & Developed by</article>
                </div>
            </section>
            {/*   for md screen */}
            <Drawer placement="right color_change" onClose={() => setOpen(false)} open={open}>
                <Link className="heading" onClick={() => setOpen(!open)} activeStyle={{ color: '#ffffff' }} to="Home" spy={true} smooth={true} delay={50} duration={50} offset={-70}>Home</Link>
                <Link className="heading" onClick={() => setOpen(!open)} activeStyle={{ color: '#FFFFFF' }} to="About" spy={true} smooth={true} delay={50} duration={50} offset={-80}>About us</Link>
                <Link className="heading" onClick={() => setOpen(!open)} activeStyle={{ color: '#FFFFFF' }} to="Download" spy={true} smooth={true} delay={50} duration={50} offset={-70}>
                    <Link className="heading" onClick={() => setOpen(!open)} activeStyle={{ color: '#FFFFFF' }} to="Features" spy={true} smooth={true} delay={50} duration={50} offset={-70}>Features </Link>
                    Download
                </Link>
                <Link className="heading" onClick={() => setOpen(!open)} activeStyle={{ color: '#FFFFFF' }} to="Contact" spy={true} smooth={true} delay={50} duration={50} offset={-70}>
                    Contact Us!
                </Link>
                <div className='download_butt'>
                    <div className='my-2'>
                        <Image height={40} preview={false} src={ios} alt='logo' />
                    </div>
                    <div>
                        <Image height={40} preview={false} src={android} alt='logo' />
                    </div>
                </div>
            </Drawer>
        </>
    )
}

export default Rico
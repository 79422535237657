
import Rico from './screen/rico';

function App() {
  return (
    <div className="">
     <Rico/>
    </div>
  );
}

export default App;



